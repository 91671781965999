import "swiper/css";
import rightArrow from "assets/right.png";


function SwiperSobreNosButtonNext({ swiperRef }) {
  return (
    <div 
      className="loja-tems__arrow loja-tems__arrow-right" 
      onClick={() => swiperRef.current?.slideNext()} // Controla o Swiper específico
    >
      <img src={rightArrow} alt="" className="loja-tems__arrow-img" />
    </div>
  );
}

export default SwiperSobreNosButtonNext;
