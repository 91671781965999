import onibusImg from "assets/onibus.png";
import preceImg from "assets/prece.png";
import relogioImg from "assets/relogio.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function MainSubjects() {

  const isMobile = window.innerWidth < 768;

  const attributes = !isMobile ? {
    initial: { opacity: 0, y: 20 },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
  } : '';


  return (
    <motion.div
      {...attributes}
      className="main-subjects"
    >
      <a href="https://tems.com.br/formularios/#/preces" target="_blank" className="main-subjects__box main-subjects__box-prece">
        <div className="main-subjects__img-box">
          <img src={preceImg} alt="" className="main-subjects__img" />
        </div>
        <div className="main-subjects__text-box">
          <h6 className="main-subjects__title">
            Pedido
            <br />
            de Oração
          </h6>

        </div>
      </a>

      <Link to="atendimentos/sao-paulo" className="main-subjects__box main-subjects__box-horario">
        <div className="main-subjects__img-box">
          <img src={relogioImg} alt="" className="main-subjects__img" />
        </div>
        <div className="main-subjects__text-box">
          <h6 className="main-subjects__title">
            Horário dos<br />Atendimentos
          </h6>
          <p className="main-subjects__subtitle">
          <strong>Seg e Ter</strong> | 14h e 19h
          <p><strong>Qua, Qui e Sex</strong> | 14h</p>
          <p><strong>Sáb</strong> | 10h e 15h</p>
          
          </p>
        </div>
      </Link>

      <Link to="caravanas" className="main-subjects__box main-subjects__box-caravanas">
        <div className="main-subjects__img-box">
          <img src={onibusImg} alt="" className="main-subjects__img" />
        </div>
        <div className="main-subjects__text-box">
          <h6 className="main-subjects__title">
            Programação:
            <br />
            <span style={{ fontWeight: "500", fontSize: "1.9rem" }}>Caravanas de Luz</span>
          </h6>
        </div>
      </Link>
    </motion.div>
  );
}

export default MainSubjects;
