import "swiper/css";
import leftArrow from "assets/left.png";


function SwiperSobreNosButtonPrev({ swiperRef }) {
  return (
    <div 
      className="loja-tems__arrow loja-tems__arrow-left" 
      onClick={() => swiperRef.current?.slidePrev()} // Controla o Swiper específico
    >
      <img src={leftArrow} alt="" className="loja-tems__arrow-img" />
    </div>
  );
}

export default SwiperSobreNosButtonPrev;
