import { useEffect } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom";
import { useFetchEventosByIdQuery } from "store";

export default function Evento() {
    const { id } = useParams();
    const { data } = useFetchEventosByIdQuery(id);

    useEffect(() => {
        if (data?.data?.attributes) {
            const { tipo, titulo } = data.data.attributes;
            if (tipo && titulo) {
                document.title = `${tipo.charAt(0).toUpperCase() + tipo.slice(1)} ${titulo}`;
            }
        }
    }, [data]);

    let content;

    if (data?.data?.attributes) {
        const { texto, video, img, tabela } = data.data.attributes;

        // Verifica se a URL do vídeo é válida (ex: YouTube)
        const renderVideo = (videoUrl) => {
            if (videoUrl) {
                const videoId = videoUrl.split("v=")[1]; // para YouTube
                if (videoId) {
                    return (
                        <iframe
                            width="857px"
                            height="480px"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="Video"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    );
                }
            }
            return null;
        };

        // Renderiza a tabela se existir, agora com wrapper para rolagem
        const renderTabela = (tabela) => {
            if (!tabela || !tabela.linhas || tabela.linhas.length === 0) {
                return null;
            }
            return (
                <div className="evento__tabela">
                    <h4>{tabela.titulo} <span>{tabela.subtitulo}</span></h4>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Cidade</th>
                                    <th>Horário</th>
                                    <th>Endereço</th>
                                    <th>Contato</th>
                                    <th>Telefone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tabela.linhas.map((linha, index) => (
                                    <tr key={index}>
                                        <td>{linha.cidade}</td>
                                        <td>{linha.horario}</td>
                                        <td>{linha.endereco}</td>
                                        <td>{linha.contato}</td>
                                        <td>{linha.telefone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        };

        content = (
            <div className="evento">
                <section
                    className="evento__header"
                    style={{
                        backgroundImage: `url(${img?.data?.attributes?.url})`,
                    }}
                ></section>
                <div className="evento__content">
                    <h4>{data.data.attributes.titulo}</h4>
                    <ReactMarkdown>{data.data.attributes.texto}</ReactMarkdown>
                </div>
                {/* Renderiza a tabela se existir */}
                {tabela && renderTabela(tabela)}
                {/* Renderiza o vídeo se existir */}
                {video && (
                    <div className="evento__video-container">
                        {renderVideo(video)}
                    </div>
                )}
            </div>
        );
    }

    return content;
}