import React, { useEffect } from 'react'
import Caravanas from '../caravanas.json'
import { useParams } from 'react-router-dom'
import PhoneIcon from 'assets/phone-2.png'
import LocationIcon from 'assets/location-2.png'
import CalendarIcon from 'assets/calendar.png'
import { useFetchCaravanasByIdQuery } from 'store'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default function CaravanaEscolhida() {

    const { id } = useParams();

    const { data, error, isFetching } = useFetchCaravanasByIdQuery(id);

    let content = '';

    if (data) {
        const caravana = data.data;
        content = (
            <div className='caravana-escolhida'>
                <div className="caravana-escolhida__header" style={{backgroundImage: `url(${caravana.attributes.img.data.attributes.url})`}}>
                    {/* <h4 className="caravana-escolhida__titulo" style={{ margin: '3rem 0 2.5rem 0' }}>{caravana.attributes.nome}</h4> */}
                </div>

                <main>
                    <div className='caravana-escolhida__information-text'>
                        <h6>Informações</h6>
                        <ReactMarkdown>
                            {caravana.attributes.informacoes}
                        </ReactMarkdown>
                    </div>

                    <div className="caravana-escolhida__information-box">
                        <div>
                            <img src={CalendarIcon} alt="" />
                            <ReactMarkdown>
                                {caravana.attributes.data}
                            </ReactMarkdown>
                        </div>
                        <div>
                            <img src={LocationIcon} alt="" />
                            <ReactMarkdown>
                                {caravana.attributes.endereco}
                            </ReactMarkdown>
                        </div>
                        <div>
                            <img src={PhoneIcon} alt="" />
                            <section>
                                <ReactMarkdown>
                                    {caravana.attributes.contato}
                                </ReactMarkdown>
                            </section>
                        </div>

                        <div className='caravana-escolhida__buttons-box'>
                            <button className='caravana-escolhida__maps-button' onClick={() => { window.location.href = caravana.attributes.googlemaps_link }}>Abrir no Maps</button>
                            <button className='caravana-escolhida__contact-button' onClick={() => { window.location.href = caravana.attributes.telegram_link }}>Entrar em contato</button>
                        </div>
                    </div>
                </main>
            </div>
        )
    } else {
        <div>Loading...</div>
    }

    return content;
}
