import { A11y, Autoplay, Navigation } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import Velas from "./Velas";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperRituaisButtonPrev from "components/ui/Swiper/SwiperRituaisButtonPrev";
import SwiperRituaisButtonNext from "components/ui/Swiper/SwiperRituaisButtonNext";
import { useFetchRituaisQuery } from "store";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function RitualDoMesPage() {
    const { data } = useFetchRituaisQuery();
    const [orderedRituais, setOrderedRituais] = useState([]);
    const currentMonth = new Date().getMonth() + 1;

    useEffect(() => {
        if (data) {
            document.title = 'Rituais';

            // Ordenar os rituais pelos meses
            const rituaisArray = data.data;
            const orderedRituaisArray = [
                ...rituaisArray.slice(1), // Pulando o primeiro elemento (índice 0)
                rituaisArray[0], // Colocando o primeiro elemento no final
            ];
            setOrderedRituais(orderedRituaisArray);
        }
    }, [data]);

    let sliderContent;

    if (orderedRituais.length > 0) {
        // Encontrar o índice do slide correspondente ao mês atual
        const currentMonthIndex = currentMonth - 2;

        // Definir o índice central do Swiper
        let initialSlide = currentMonthIndex;

        sliderContent = (
            <>
                <Swiper
                    modules={[Navigation, Autoplay, A11y]}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    spaceBetween={40}
                    slidesPerView={3}
                    initialSlide={initialSlide}
                    breakpoints={{
                        0: {
                            spaceBetween: 20,
                            slidesPerView: 1.3,
                        },
                        768: {
                            slidesPerView: 2
                        },
                        1200: {
                            slidesPerView: 3
                        },
                    }}
                    loop={true}
                    navigation={{
                        nextEl: '.rituais__arrow-right',
                        prevEl: '.rituais__arrow-left',
                    }}
                    scrollbar={{ draggable: true }}
                >
                    {orderedRituais.map(({ attributes, id }) => {
                        return (
                            <SwiperSlide key={id}>
                                <Link to={`/rituais/${id}`}>
                                    <figure className="rituais__ritual">
                                        <img className="rituais__ritual-img" src={attributes.img.data.attributes.url} alt={attributes.nome} />
                                        <figcaption className="rituais__ritual-text-box">
                                            <h6 className="rituais__ritual-titulo">{attributes.nome}</h6>
                                            <p className="rituais__ritual-subtitulo">{attributes.descricao.toUpperCase()}</p>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                <SwiperRituaisButtonPrev />
                <SwiperRituaisButtonNext />
            </>
        );
    }

    return (
        <div className="rituais">
            <h4 className="rituais__titulo">Rituais do Ano</h4>
            <p className="rituais__subtitulo">
                Os 12 rituais do ano, foram trazidos para que possamos através da fé e
                da disciplina equilibrar nossas vidas e alcançar nossos propósitos.
            </p>

            <div className="rituais__slider">
                {sliderContent}
            </div>

            <div className="rituais__velas-box">
                <div className="rituais__velas-titulo">Velas do Mês | 2025</div>
                <Velas />
            </div>
        </div>
    );
}

export default RitualDoMesPage;
