import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper";
import SwiperSobreNosButtonPrev from "../../components/ui/Swiper/SwiperSobreNosButtonPrev";
import SwiperSobreNosButtonNext from "../../components/ui/Swiper/SwiperSobreNosButtonNext";
import { useEffect, useRef } from "react";
import HeaderImage from "../../assets/header_sobre_nos.png";
import AdolfoRealista from "../../assets/adolfo_realista.png";
import Image1 from "../../assets/1_sobre_nos.png";
import Image2 from "../../assets/2_sobre_nos.png";
import Image3 from "../../assets/3_sobre_nos.png";
import Image4 from "../../assets/4_sobre_nos.png";
import Image5 from "../../assets/5_sobre_nos.png";
import Image6 from "../../assets/6_sobre_nos.png";
import Image7 from "../../assets/7_sobre_nos.png";
import Image8 from "../../assets/8_sobre_nos.png";
import Image9 from "../../assets/9_sobre_nos.png";
import Image10 from "../../assets/10_sobre_nos.png";
import Image11 from "../../assets/11_sobre_nos.png";
import Image12 from "../../assets/12_sobre_nos.png";
import Image13 from "../../assets/13_sobre_nos.png";
import Image14 from "../../assets/14_sobre_nos.png";
import Image15 from "../../assets/15_sobre_nos.png";

function SobreNosPage() {
  useEffect(() => {
    document.title = "Sobre Nós";
  }, []);

  const swiperRef1 = useRef(null);
  const swiperRef2 = useRef(null);
  const swiperRef3 = useRef(null);

  const imagesSlide1 = [Image1, Image2, Image3, Image4, Image5, Image6];
  const imagesSlide2 = [Image7, Image8, Image9, Image10, Image11];
  const imagesSlide3 = [Image12, Image13, Image14, Image15];

  return (
    <div className="sobre-nos">
      <section className="sobre-nos__header-section">
        <header className="sobre-nos__header">
          <img
            src={HeaderImage}
            alt="Header Sobre Nós"
            className="sobre-nos__header-img"
          />
        </header>
      </section>

      {/* Swiper 1 */}
      <section className="sobre-nos__content-1">
        <div className="sobre-nos__swiper-box">
          <Swiper
            modules={[Navigation, A11y, Autoplay]}
            onSwiper={(swiper) => (swiperRef1.current = swiper)}
            loop={true}
            slidesPerView={1}
            spaceBetween={40}
          >
            {imagesSlide1.map((image, index) => (
              <SwiperSlide key={index}>
                <figure className="sobre-nos__item">
                  <img src={image} alt={`Image ${index + 1}`} />
                </figure>
              </SwiperSlide>
            ))}
          </Swiper>
          <SwiperSobreNosButtonPrev swiperRef={swiperRef1} />
          <SwiperSobreNosButtonNext swiperRef={swiperRef1} />
        </div>
        <div className="sobre-nos__texto-1">
          <h4>Sobre Nós</h4>
          <p>
            O Templo Espiritual Maria Santíssima é uma casa religiosa,
            ecumênica, sem fins lucrativos e que tem como objetivo fortalecer a
            vivência do amor incondicional ensinado por Jesus Cristo.
          </p>
        </div>
      </section>

      {/* Swiper 2 */}
      <section className="sobre-nos__content-2">
        <div className="sobre-nos__texto-2">
          <p>
            O TEMS trabalha para que a paz e os sentimentos nobres permaneçam em
            todos os seus frequentadores e voluntários, levando auxílio
            espiritual para cada um, sem distinção de cor, crença, gênero ou
            ideologia. Além de ajudar na cura do corpo espiritual, presta
            assistência à comunidade, levando o alimento material para centenas
            de famílias carentes da região.
          </p>
        </div>
        <div className="sobre-nos__swiper-box_reverse">
          <Swiper
            modules={[Navigation, A11y, Autoplay]}
            onSwiper={(swiper) => (swiperRef2.current = swiper)}
            loop={true}
            slidesPerView={1}
            spaceBetween={40}
          >
            {imagesSlide2.map((image, index) => (
              <SwiperSlide key={index}>
                <figure className="sobre-nos__item">
                  <img src={image} alt={`Image ${index + 1}`} />
                </figure>
              </SwiperSlide>
            ))}
          </Swiper>
          <SwiperSobreNosButtonPrev swiperRef={swiperRef2} />
          <SwiperSobreNosButtonNext swiperRef={swiperRef2} />
        </div>
      </section>

      <section className="sobre-nos__adolfo">
        <div
          className="sobre-nos__adolfo-background"
          style={{
            backgroundColor: "#FFF6E7",
            backgroundRepeat: "no-repeat",
            backgroundSize: "25%",
            backgroundPosition: "90% 50%",
          }}
        >
          <div className="sobre-nos__adolfo-texto">
            <p>
              A Casa foi fundada no dia 18 de maio de 2000 pelo sensitivo
              Gilberto Rissato e por seu mentor Adolfo Schultz, coordenador
              espiritual, que determinou:
            </p>
          </div>
          <div className="sobre-nos__adolfo_realista">
            <img src={AdolfoRealista} alt="Imagem Realista Adolfo Schultz" />
          </div>
          <div className="sobre-nos__descricao-adolfo">
            <p>Primeira pintura medunica de Adolfo Schultz</p>
            <p>Imagem gerada por IA com base na pintura</p>
          </div>
          <div className="sobre-nos__adolfo-texto-2">
            <p>
              “A missão do TEMS é reunir, redefinir, reeducar e reespiritualizar
              os seios familiares com a mensagem do Evangelho do Cristo de Deus
              na Terra, tendo a humildade como base, a disciplina como regra e a
              perseverança como objetivo. Esses são pontos fundamentais para que
              um espírito, em qualquer condição, alcance o seu propósito”.
            </p>
          </div>
        </div>
      </section>

      {/* Swiper 3 */}
      <section className="sobre-nos__content-3">
        <div className="sobre-nos__swiper-box">
          <Swiper
            modules={[Navigation, A11y, Autoplay]}
            onSwiper={(swiper) => (swiperRef3.current = swiper)}
            loop={true}
            slidesPerView={1}
            spaceBetween={40}
          >
            {imagesSlide3.map((image, index) => (
              <SwiperSlide key={index}>
                <figure className="sobre-nos__item">
                  <img src={image} alt={`Image ${index + 1}`} />
                </figure>
              </SwiperSlide>
            ))}
          </Swiper>
          <SwiperSobreNosButtonPrev swiperRef={swiperRef3} />
          <SwiperSobreNosButtonNext swiperRef={swiperRef3} />
        </div>
        <div className="sobre-nos__texto-3">
          <p>
            Todos os dias, são realizados atendimentos espirituais, cursos,
            terapias integrativas e holísticas e atendimentos sociais para que
            todos possam receber sempre o melhor da Casa de Maria Santíssima. Os
            atendimentos espirituais estão chegando em várias partes do Brasil e
            do mundo através das Caravanas de Luz, que proporcionam conforto e
            ajuda espiritual para todos que buscam se harmonizar intimamente e,
            consequentemente a paz se dará a todos que estão à sua volta.
          </p>
        </div>
      </section>
    </div>
  );
}

export default SobreNosPage;
