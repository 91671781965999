import capaCaravana from 'assets/capa_caravana_de_luz.png';
import SearchIcon from 'assets/search-2.png';
import CaravanaList from './CaravanaList';
import { useFetchCaravanasQuery } from 'store';
import { useEffect, useState } from 'react';

function CaravanasDeLuzPage() {
    const { data, error, isFetching } = useFetchCaravanasQuery();
    const [caravanas, setCaravanas] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [filterTerapias, setFilterTerapias] = useState(false);

    useEffect(() => {
        if (data) {
            // Ordena globalmente as caravanas pela data_atendimento
            const sortedCaravanas = [...data.data].sort(
                (a, b) => new Date(a.attributes.data_atendimento) - new Date(b.attributes.data_atendimento)
            );
            setCaravanas(sortedCaravanas);
        }
    }, [data]);

    let content = '';

    const estados = ['Ver todos', 'Acre', 'Alagoas', 'Amapá', 'Amazonas', 'Bahia', 'Ceará', 'Distrito Federal', 'Espírito Santo', 'Goiás', 'Maranhão', 'Mato Grosso', 'Mato Grosso do Sul', 'Minas Gerais', 'Pará', 'Paraíba', 'Paraná', 'Pernambuco', 'Piauí', 'Rio de Janeiro', 'Rio Grande do Norte', 'Rio Grande do Sul', 'Rondônia', 'Roraima', 'Santa Catarina', 'São Paulo', 'Sergipe', 'Tocantins'];

    const cidadesFiltro = (estadoEscolhido, event) => {
    clear();
    const el = event.target;
    el.classList.add('caravanas__filter-cidade-tag-selected');

    let caravanasFiltered;

    if (estadoEscolhido !== 'Ver todos') {
        caravanasFiltered = data.data.filter(caravana => {
            return (
                (selectedType === 'caravanas' && !caravana.attributes.cafe_com_espiritualidade && !caravana.attributes.terapias_holisticas) ||
                (selectedType === 'cafe' && caravana.attributes.cafe_com_espiritualidade) ||
                (selectedType === 'terapias' && caravana.attributes.terapias_holisticas) ||
                (selectedType === null)
            ) && caravana.attributes.estado === estadoEscolhido;
        });
    } else {
        // Quando "Ver todos" é selecionado, pega todos os dados
        caravanasFiltered = [...data.data];
    }

    // Ordena globalmente pela data_atendimento
    const sortedCaravanas = caravanasFiltered.sort(
        (a, b) => new Date(a.attributes.data_atendimento) - new Date(b.attributes.data_atendimento)
    );

    setCaravanas(sortedCaravanas);
};

    const typeFilter = (type) => {
        clear();
        let arrayFiltered;
        if (selectedType === type) {
            setSelectedType(null);
            arrayFiltered = data.data;
        } else {
            setSelectedType(type);
            arrayFiltered = data.data.filter((caravana) => {
                if (type === 'caravanas') {
                    return !caravana.attributes.cafe_com_espiritualidade && !caravana.attributes.terapias_holisticas;
                } else if (type === 'cafe') {
                    return caravana.attributes.cafe_com_espiritualidade;
                } else if (type === 'terapias') {
                    return caravana.attributes.terapias_holisticas;
                } else {
                    return true;
                }
            });
        }

        setCaravanas(arrayFiltered);
    };

    const clear = () => {
        const searchInput = document.getElementById('search-input');
        searchInput.value = '';

        const tags = document.querySelectorAll('.caravanas__filter-cidade-tag');
        tags.forEach(tag => {
            tag.classList.remove('caravanas__filter-cidade-tag-selected');
        });
    };

    const handleSearchChange = (event) => {
        if (event.target.value.length > 2) {
            const caravanasFiltered = data.data.filter(caravana =>
                (
                    caravana.attributes.nome.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    caravana.attributes.estado.toLowerCase().includes(event.target.value.toLowerCase())
                ));
            
            // Ordena globalmente os resultados pela data_atendimento
            const sortedCaravanas = [...caravanasFiltered].sort(
                (a, b) => new Date(a.attributes.data_atendimento) - new Date(b.attributes.data_atendimento)
            );
            setCaravanas(sortedCaravanas);
        } else {
            // Ordena todas as caravanas pela data_atendimento
            const sortedCaravanas = [...data.data].sort(
                (a, b) => new Date(a.attributes.data_atendimento) - new Date(b.attributes.data_atendimento)
            );
            setCaravanas(sortedCaravanas);
        }
    };

    if (data) {
        content = <CaravanaList data={caravanas} />;
    }

    return (
        <div className="caravanas">
            <div className="caravanas__header" style={{ backgroundImage: `url(${capaCaravana})` }}>
                <h4 className="caravanas__titulo">Caravanas de Luz</h4>
            </div>

            <main>
                <div className="caravanas__filter">
                    <button
                        className="caravanas__filter-clear-button"
                        onClick={() => {
                            clear();
                            setSelectedType(null);
                            const sortedCaravanas = [...data.data].sort(
                                (a, b) => new Date(a.attributes.data_atendimento) - new Date(b.attributes.data_atendimento)
                            );
                            setCaravanas(sortedCaravanas);
                        }}
                    >Limpar</button>

                    <p className="caravanas__filter-text">Filtro</p>
                    <span
                        className={`caravanas__filter-cidade-tag ${selectedType === 'cafe' ? 'caravanas__filter-cidade-tag-selected' : ''}`}
                        id="cafe"
                        onClick={() => typeFilter('cafe')}
                    >
                        Café com Espiritualidade
                    </span>

                    <span
                        className={`caravanas__filter-cidade-tag ${selectedType === 'terapias' ? 'caravanas__filter-cidade-tag-selected' : ''}`}
                        id="terapias"
                        onClick={() => typeFilter('terapias')}
                    >
                        Terapias Holísticas
                    </span>

                    <p className="caravanas__filter-estados-text">Estados</p>

                    {estados.map(estado => (
                        <span
                            onClick={(event) => cidadesFiltro(estado, event)}
                            className="caravanas__filter-cidade-tag"
                            key={estado}
                        >
                            {estado}
                        </span>
                    ))}

                </div>

                <div className="caravanas__list-box">
                    <p className="caravanas__text">Todas as próximas Caravanas de Luz</p>
                    {content}
                </div>
            </main>
        </div>
    );
}

export default CaravanasDeLuzPage;
