import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PhoneIcon from 'assets/phone-2.png';
import LocationIcon from 'assets/location-2.png';
import CalendarIcon from 'assets/calendar.png';
import { useFetchCursosByIdQuery } from 'store';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export default function CursoEscolhido() {

    const { id } = useParams();

    const { data, error, isFetching } = useFetchCursosByIdQuery(id);

    let content = '';

    if (data) {
        const curso = data.data;
        content = (
            <div className='curso-escolhido'>
                <div className="curso-escolhido__header" style={{ backgroundImage: `url(${curso.attributes.img.data.attributes.url})` }}>
                    {/* <h4 className="curso-escolhido__titulo" style={{ margin: '3rem 0 2.5rem 0' }}>{curso.attributes.nome}</h4> */}
                </div>

                <main>
                    <div className='curso-escolhido__information-text'>
                        <h6>Informações</h6>
                        <ReactMarkdown>
                            {curso.attributes.informacoes}
                        </ReactMarkdown>
                    </div>

                    <div className="curso-escolhido__information-box">
                        <div>
                            <img src={CalendarIcon} alt="" />
                            <ReactMarkdown>
                                {curso.attributes.data}
                            </ReactMarkdown>
                        </div>
                        <div>
                            <img src={LocationIcon} alt="" />
                            <ReactMarkdown>
                                {curso.attributes.endereco}
                            </ReactMarkdown>
                        </div>
                        <div>
                            <img src={PhoneIcon} alt="" />
                            <section>
                                <ReactMarkdown>
                                    {curso.attributes.contato}
                                </ReactMarkdown>
                            </section>
                        </div>

                        <div className='curso-escolhido__buttons-box'>
                            <button className='curso-escolhido__maps-button' onClick={() => { window.location.href = curso.attributes.googlemaps_link }}>Abrir no Maps</button>
                            <button className='curso-escolhido__contact-button' onClick={() => { window.location.href = curso.attributes.whatsapp_link }}>Entrar em contato</button>
                        </div>
                    </div>
                </main>
            </div>
        );
    } else {
        content = <div>Loading...</div>;
    }

    return content;
}
